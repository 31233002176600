import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { LandingPageContext } from "./UtilitiesLandingPage";
import { useSelector } from "react-redux";
import Status from "./Status";
import moment from "moment";
import { getSeedValues } from "../../../../Utils/UtilFunctions/useCaseUtilFunctions";
import { DYNAMICQUERY } from "../../../../Utils/UtilFunctions/constant";
import { Asset_Usecase } from "../../../../models/Types_Interfaces";
import { isEmpty } from "lodash";
import { hasInvalidCubePayload } from "../../../../Utils/UtilFunctions/cubeUtil";
import useTrackedCubeQuery from "../../../../Utils/UtilFunctions/useTrackedCubeQuery";

function AssetStatusIndicator(props: any) {
  const { asset } = props;
  const contextProps: any = React.useContext(LandingPageContext);
  const usecaseList = useSelector((state: any) => state.usecaseList);
  const seedsList = useSelector((state: any) => state.seedsList);
  const [currentUsecase, setCurrentUsecase] = React.useState<any>([]);

  //getting usecase from url
  const usecaseRoute = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1,
    window.location.href.length
  );

  // use effect to find and sotre use case details using url
  useEffect(() => {
    setCurrentUsecase(
      usecaseList.usecases.find((usecase: Asset_Usecase) => {
        return usecase.routes_use_code === usecaseRoute;
      })
    );
  }, [usecaseList.usecases]);

  const dynamicQuery: any =
    getSeedValues(
      seedsList,
      DYNAMICQUERY,
      "Utilities CBM",
      contextProps.defaultPlantCode
    ).length === 0
      ? getSeedValues(seedsList, DYNAMICQUERY, "Utilities CBM", "HOU")
      : getSeedValues(
          seedsList,
          DYNAMICQUERY,
          "Utilities CBM",
          contextProps.defaultPlantCode
        );

  const cubeTag =
    dynamicQuery !== undefined &&
    dynamicQuery.assetTags?.find((data: any) => {
      return data.asset === props.asset?.asset_name;
    }) === undefined
      ? dynamicQuery.assetTags?.find((data: any) => {
          return data.asset === "others";
        })
      : dynamicQuery.assetTags?.find((data: any) => {
          return data.asset === props.asset?.asset_name;
        });

  var uniqueTags = contextProps.tagsArray.filter((tag: any) => {
    return tag.includes(
      `${
        props.asset.devices.filter((device: any) => {
          return device.usecase === undefined
            ? true
            : device.usecase ===
                usecaseList.usecases.find((usecase: any) => {
                  return (
                    usecase.level_use_case === currentUsecase?.level_use_case
                  );
                })?._id;
        })[0]?.tag
      }_`
    );
  });

  if (
    uniqueTags.filter(
      (item: any, index: any) => uniqueTags.indexOf(item) === index
    ).length > 1
  ) {
    uniqueTags =
      uniqueTags.filter((tag: any) => {
        return tag.endsWith(`_${cubeTag?.tag}`);
      }).length === 1
        ? uniqueTags.filter((tag: any) => {
            return tag.endsWith(`_${cubeTag?.tag}`);
          })
        : uniqueTags.filter((tag: any) => {
            return tag.endsWith(cubeTag?.tag);
          });
  }

  const cubeName: any = contextProps.correspondingCubes.find((cube: any) => {
    return (
      cube.name.includes("LastValueAgg") && !cube.name.includes("Actionable")
    );
  })?.name;

  const connectionStatusQuery: any = {
    measures: [`${cubeName}.lastValue`],
    filters: [
      {
        member: `${cubeName}.tagName`,
        operator: "equals",
        values: uniqueTags.filter(
          (item: any, index: any) => uniqueTags.indexOf(item) === index
        ),
      },
    ],
    dimensions: [`${cubeName}.description`, `${cubeName}.deviceName`],
    order: [[`${cubeName}.lastValue`, "desc"]],
  };

  let renderProps: any = useTrackedCubeQuery(connectionStatusQuery, {
    skip: hasInvalidCubePayload(connectionStatusQuery) || uniqueTags.length === 0 ? true : false,
  });
  renderProps = uniqueTags.length === 0 ? null : renderProps;

  const cubeValue: any =
    renderProps !== null &&
    renderProps.resultSet?.loadResponse.results[0]?.data[0] !== undefined
      ? renderProps.resultSet?.loadResponse.results[0]?.data[0][
          `${connectionStatusQuery.measures[0]}`
        ]
      : null;

  if (isEmpty(dynamicQuery)) return null;
  
  return renderProps?.isLoading ? (
    <CircularProgress size="40px" />
  ) : cubeValue !== null ? (
    <Status
      value={
        Number(parseFloat(cubeValue).toFixed(2)) >= 1
          ? cubeTag.value["1"]
          : Number(parseFloat(cubeValue).toFixed(2)) <= 0
          ? cubeTag.value["0"]
          : "intermediate"
      }
      asset={asset}
    />
  ) : (
    <Status value={"nostatus"} asset={asset} />
  );
}

export default AssetStatusIndicator;
